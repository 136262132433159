<div class="footer-cont">
    <div class="container mt-5 center">
        <div class="alignFooter">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="center">
                        <p class="footerTitle">Tamara Basepayne</p>
                    </div>
                    <div class=" footerText adjustFooter">
                        <p class="mb-2 center">Director of Outdoor Education and STEM Programs </p>
                        <a class="email-link" href="mailto: tbasepayne@sjcoe.net" target="_blank">tbasepayne@sjcoe.net</a>
                        <p class="mt-2 center"><a class="footerText" href="tel:(209)817-8053">(209)817-8053</a></p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="center">
                        <p class="footerTitle">STEM Programs</p>
                    </div>
                    <div class="footerText textLeft">
                        <p class="mb-2 center">PO Box 213030</p>
                        <p class="mb-2 center">Stockton CA 95213</p>
                        <p class="mt-2 center"><a style="color: #000;"href="tel:(209)468-4880">(209)468-4880</a></p>
                        <p class="iconSize center">
                            <a href="https://www.facebook.com/SJCOESTEM" target="_blank" aria-label="Facebook link button.">
                                <i class="fab fa-facebook-f pr-3"></i>
                            </a>
                            <a href="https://twitter.com/sjcoestem" target="_blank" aria-label="Twitter link button.">
                                <i class="fab fa-twitter pr-3"></i>
                            </a>
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-12">
                    <div class="center">
                        <p class="footerTitle">Newsletter</p>
                    </div>
                    <div class="center">
                        <a target="_blank" href="https://visitor.r20.constantcontact.com/manage/optin?v=0018nJcGPmgGojZvChcbv_pDp8yboLT-kceGIkYUsjbLkF2vaMHWB_Xk7wScZBkZno-6StKvOl0pzMvzSLWvBs_PFi4_OmIa6bgREdF-GnMYrM%3D">
                            <p class="footerText underLined">Sign up for our newsletter</p>
                        </a>
                    </div>
                </div>
                <div class="col-12 p-0 pb-3 pt-2 ccenter">
                    <a href="https://sjcoe.org/" target="_blank">
                        <img class="footerlogo" src="../../../assets/images/SJCOE-Formal.png" alt="Logo of San Joaquin County Office Of Education">                    
                    </a>
                </div>
                <div class="col-12 center d-flex justify-content-center">
                    <p>​Copyright © SJCOE 2022</p>
                </div>
            </div>
        </div>
    </div>
</div>