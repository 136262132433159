import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventdataService } from 'src/app/shared/services/eventdata.service';
import { EventService } from 'src/app/shared/services/event.service';
import { IEvent } from 'src/app/shared/models/event';
import { DomSanitizer } from '@angular/platform-browser'
import { OpenStreetMapProvider } from 'leaflet-geosearch';
const provider = new OpenStreetMapProvider();

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  public isCollapsed = true;
  public isCollapsedContact = true;
  public isCollapsedLocation = true;

  // router: any;

  constructor(public _router: Router, public activeRoute: ActivatedRoute, private sanitizer: DomSanitizer, private eventService: EventService, private _eventDataService: EventdataService) { }

  $routeSubscription: Subscription = new Subscription();
  stateParams: any;
  event = new IEvent();
  long: any;
  lat: any;
  mymap: any;
  mymap2: any;
  mapA: any;
  mapB: any;
  mapC: any;
  mapD: any;
  mapE: any;
  mapF: any;
  mapG: any;
  mapH: any;
  mapI: any;
  mapMain: any;

  eventImage: string;

  toggleDisplay() {

    let cinfo = document.getElementById('contactChev')!;
    cinfo.classList.toggle('fa-chevron-up');
    cinfo.classList.toggle('fa-chevron-down');

  }

  ngOnInit(): void {
    this.$routeSubscription.add(this.activeRoute.params.subscribe(async params => {

      this.stateParams = params;
      this.event = await this.eventService.getEvent(this.stateParams.typeId);
      this.event.images = 'https://stemprodstoragewest.blob.core.windows.net/photos/' + this.event.images.split("|")[0];
      provider.search({ query: this.event.event_Address }).then((result: any) => {


        this.lat = result[0].x;
        this.long = result[0].y;
        // console.log(this.lat);
        // console.log(this.long);

        this.mapA = '<iframe width="250" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox='

        this.mapB = '\%2C'

        this.mapC = '%2C--'

        this.mapD = '%2C--'

        this.mapE = '&amp;layer=mapnik&amp;marker='

        this.mapF = '" style="border: 1px solid black"></iframe><br/>'

        this.mapMain = this.lat + "%2C" + this.long



        this.mymap = '<iframe width="250" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=' + this.lat + "%2C" + this.long + '%2C' + this.lat + "%2C" + this.long + '&amp;layer=mapnik&amp;marker=' + this.long + "%2C" + this.lat + '" style="border: 1px solid #828282"></iframe><br/>'

        this.mymap2 = '<div class="responsive"><iframe width="350" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=' + this.lat + "%2C" + this.long + '%2C' + this.lat + "%2C" + this.long + '&amp;layer=mapnik&amp;marker=' + this.long + "%2C" + this.lat + '" style="border: 1px solid #828282"></iframe></div><br/>'


        this.mymap = this.sanitizer.bypassSecurityTrustHtml(this.mymap);
        this.mymap2 = this.sanitizer.bypassSecurityTrustHtml(this.mymap2);

        //  console.log( this.mymap );





      });
    }));
  }
}
