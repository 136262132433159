



<div class="container d-flex text-center justify-content-center px-0 pt-3">
    <nav class="navbar navbar-expand-lg  fix-width fix-navbar px-0">
        <a class="" href="/Home"><img src="./../../../assets/images/Lit-Logo.jpg" class="logo" alt="SJCOE Logo"></a>
        <button class="navbar-toggler bg-light" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
            &#9776;
        </button>
        <div [ngClass]="{'hide': isMenuCollapsed, 'show': !isMenuCollapsed}" class="super-nav navbar-collapse collapse">
            <span class="pointer super-x" (click)="isMenuCollapsed = !isMenuCollapsed">
                <p class="xSpot"><i class="fa fa-times pr-3"></i></p>
            </span>
            <ul class="navbar-nav ml-auto">
                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" id="dropdownBasic2"
                        (click)="isMenuCollapsed = !isMenuCollapsed" SmoothScroll
                        href="#resources" >Network
                        Resources</a>
                </ul>

                    <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                        <a class="nav-link nav-white pointer" id="dropdownBasic2"
                            (click)="isMenuCollapsed = !isMenuCollapsed" SmoothScroll
                            href="#green" >Green Schools</a>
                    </ul>


                   
                <ul ngbDropdown class="d-inline-block nav-item space-nav-home" *ngIf="this.litcards?.length > 0 ">
                    <a class="nav-link nav-white pointer" (click)="isMenuCollapsed = !isMenuCollapsed"
                        id="dropdownBasic2" SmoothScroll href="Home#events">Events</a>
                </ul>


                <ul *ngIf="this.professionalDevCards?.length > 0 " ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a  class="nav-link nav-white pointer" id=""
                        (click)="isMenuCollapsed = !isMenuCollapsed" SmoothScroll
                        href="#proDev" >Professional Development</a>
                </ul>

                <ul  ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a  class="nav-link nav-white pointer" id="" target="_blank"
                       
                        href="https://www.sjcoescience.org/" >SJCOE STEM Programs</a>
                </ul>

                <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" id="dropdownBasic1" ngbDropdownToggle>More</a>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu">
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem nav-white" (click)="isMenuCollapsed = !isMenuCollapsed"
                               href="https://sites.google.com/sjcoe.net/centralcacelp/home"
                               target="_blank">CREEC Central Resources</a>
                        </li>
                        <li class="menuLi">
                            <a ngbDropdownItem class="menuItem nav-white" (click)="isMenuCollapsed = !isMenuCollapsed"
                                href="https://outdooreducation.sjcoescience.org/" target="_blank">Outdoor Education</a>
                        </li>
                    </div>
                </ul>


                <!-- <ul ngbDropdown class="d-inline-block nav-item space-nav-home">
                    <a class="nav-link nav-white pointer" id="dropdownBasic2" href="#events">Professional Learning</a>
                </ul> -->
            </ul>
        </div>
    </nav>
</div>