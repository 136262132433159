<!-- Hero Banner -->
<div class="hero-banner">
    <div class="col-sm-12 center py-5 px-0">
        <img src="../../../assets/images/literacy-big-text.png" class="img-fluid bannerImg"
            alt="Banner Image for Environmental Literacy.">
    </div>
    <div class="svgWave">
        <svg class="wave1Style" viewBox="0 0 500 150" preserveAspectRatio="none">
            <path d="M-25.67,54.77 C142.49,-97.20 334.93,131.73 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                style="stroke: none; fill: #6B9E80;"></path>
        </svg>
        <svg class="wave2Style" viewBox="0 0 500 150" preserveAspectRatio="none">
            <path d="M-25.67,54.77 C142.49,-97.20 334.93,131.73 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                style="stroke: none; fill: #F8F7F3;"></path>
        </svg>
    </div>
</div>
<!-- Banner -->
<div class="creamBG">
    <div class="container">
        <div class="row pt-3 pb-4">
            <div class="col-12">
                <h1 class="bannerTitle">The SJCOE Vision for Environmental Literacy</h1>
                <p class="bannerText">
                    The environmental literacy vision of the San Joaquin County Office of Education is to equitably <span style="font-weight:600;">educate</span> and empower school communities to understand the intersections between human and natural systems in the past, present and future, and to <span style="font-weight:600;">inspire</span> civic responsibility on local and global environmental issues in order to <span style="font-weight:600;">innovate</span> solutions towards a more sustainable future.
                </p>
            </div>

            <div class="col-12 p-0 pt-3 center">
                <a href="https://sjcoe.org/" target="_blank">
                    <img src="../../../assets/images/Logo.svg" alt="Logo of San Joaquin County Office Of Education">                    
                </a>
            </div>
        </div>
       
    </div>
</div>
<!-- IMAGE ROW 1 -->
<div id="resources" class="container ">
    <div class="row center mt-5 mb-5">
        <div class="col-12" style="padding-bottom:35px;">
            <h1 class="h2bannerTitle">Environmental Literacy Networks</h1>
            <p style="margin-bottom:5px;">The goal of the environmental literacy networks in Region 6 is to connect and support environmental
                education between community-based partners, educators, students, school districts and sites. We will
                meet throughout the year to address challenges, support each other's work, build capacity, foster
                collaboration, and further integrate our districts and schools to address local and global environmental
                challenges.</p>
                <p><a class="underLine" href="https://sites.google.com/sjcoe.net/sjcoe-el-networks/home" target="_blank">To learn more about the Region 6 Environmental Literacy Networks.</a></p>

                <a class="btn blk-btn" href="https://docs.google.com/forms/d/e/1FAIpQLSdUDEKyU9MtUCsZgxB31K6AzBUAiemlYCKlH2VTC9zPi_t86g/viewform" target="_blank">Join the Networks</a>

        </div>

        <div class="resources-container col-12 grid-cont2">

            <div class="lit-card green-shadow">
                <img src="./../../../assets/images/YEL-C.jpg" alt="Image for Youth For Environmental Literacy"
                    class="lit-img">
                <div class="lit-cardBody">
                    <h2 class="elTopic mb-0">YEL</h2>
                    <p class="elSubtopic">Youth For Environmental Literacy - Region 6</p>
                    <p class="elText2">
                        This network builds a community of like-minded high school
                        students that are passionate about local environmental
                        issues, eager to share about environmental projects and clubs
                        at their schools, and keen to explore careers in the
                        environmental field.
                    </p>
                    <div class="theLink">
                        <a href="https://sites.google.com/sjcoe.net/sjcoe-el-networks/home/yel" target="_blank"
                            class="elLinks2">
                            Visit Youth for Environmental Literacy
                        </a>
                        <span class="elLinkLine"></span>
                    </div>
                </div>
            </div><!-- end lit card"-->

            <div class="lit-card green-shadow">
                <img src="./../../../assets/images/TEL-C.jpg" alt="Image for Teachers For Environmental Literacy"
                    class="lit-img">
                <div class="lit-cardBody">
                    <h2 class="elTopic mb-0">TEL</h2>
                    <p class="elSubtopic">Teachers For Environmental Literacy - Region 6</p>
                    <p class="elText2">
                        We are looking for passionate K-12 educators to join this
                        network and help lead the charge for environmental
                        literacy in San Joaquin County. The focus of this network
                        is all things environmental literacy ranging from the CA
                        Green Ribbon Schools Award, the CA Seal of Civic
                        Engagement, California's water network, experiential
                        place-based learning, environmental justice, climate change
                        education, and more.
                    </p>
                    <div class="theLink">
                        <a href="https://sites.google.com/sjcoe.net/sjcoe-el-networks/home/tel" target="_blank"
                            class="elLinks2">
                            Visit Teachers for Environmental Literacy
                        </a>
                        <span class="elLinkLine"></span>
                    </div>
                </div>
            </div><!-- end lit card"-->


            <div class="lit-card green-shadow">
                <img src="./../../../assets/images/cpel-OPT.png"
                    alt="Image for Community Partners for Environmental Literacy" class="lit-img">

                <div class="lit-cardBody">
                    <h2 class="elTopic mb-0">CPEL</h2>
                    <p class="elSubtopic">Community Partners for Environmental Literacy - Region 6</p>

                    <p class="elText2">

                        The goal of the CPEL network is to connect YOU (city, county,
                        federal organizations, non profits, green businesses etc.)
                        and the environmental work you are doing in the real world
                        to the students, schools and districts in San Joaquin County.
                        Our goal at SJCOE is to help you make those connections and
                        support you in your efforts.
                    </p>
                    <div class="theLink">
                        <a href="https://sites.google.com/sjcoe.net/sjcoe-el-networks/home/cpel" target="_blank"
                            class="elLinks2">
                            Visit Community Partners for Environmental Literacy
                        </a>
                        <span class="elLinkLine"></span>
                    </div>
                </div>
            </div><!-- end lit card"-->

            <div class="lit-card green-shadow">
                <img src="./../../../assets/images/Gel-C.jpg" alt="Image for Green Engagement Leaders" class="lit-img">
                <div class="lit-cardBody">
                    <h2 class="elTopic mb-0">GEL</h2>
                    <p class="elSubtopic">Green Engagement Leaders - SJCOE</p>
                    <p class="elText2">
                        San Joaquin County Office of Education is going green! Join the network if you are an SJCOE
                        employee and are interested in joining the organization-wide effort to implement sustainable
                        practices. Some of our activities include hearing from experts on environmental topics, working
                        towards the CA Green Ribbon Schools award for our county office, planning outdoor experiences
                        for SJCOE families.
                    </p>
                    <div class="theLink">
                        <a href="https://sites.google.com/sjcoe.net/sjcoe-el-networks/gel" target="_blank"
                            class="elLinks2">
                            Visit Green Engagement Leaders
                        </a>
                        <span class="elLinkLine"></span>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div><!-- end resources container-->

        <!-- green schools -->
        <div class="green-wrap" >
        <div id="green" class="container">
            <div class="row center">
                <div class="col-12 py-3 mb-3">
                    <h1 class="h2bannerTitle2">Green Schools</h1>
                    <p style="color:#fff;">In order to create sustainable and climate ready schools and develop environmentally literate communities we need to make changes to our Campus, Curriculum and Community & Culture.</p>

                    <a class="btn wht-btn" href="https://sites.google.com/sjcoe.net/greenschools/home" target="_blank">Learn More</a>
                </div>
            </div>
        </div>

        <div class="resources-container container grid-cont">


            <div class="lit-card reddish-shadow">
                <img src="./../../../assets/images/cagreenribbon-OPT.png" alt="Image for CA Green Ribbon Schools"
                    class="lit-img">
                <div class="lit-cardBody">
                    <h2 class="elTopic mb-0">CA Green Ribbon Schools</h2>
                    <!--<p class="elSubtopic">Virtual Workshop Series - Region 6</p>-->
                    <p class="elText2">
                        The <a href="https://www.cde.ca.gov/ls/fa/sf/greenribbonprog.asp">Ca Green Ribbon Schools</a> award recognizes schools for their commitment to reducing environmental impact and costs, improving health and wellness, and providing effective environmental education. The SJCOE is proud to have been honored with Silver Level Green Ribbon School Award for the 2023-24 and 2022-2023 school years.  Learn more about the GRS award in our recorded workshop series and use the resources to start the Green Ribbon School Process with your school. For additional support contact Tamara Basepayne at <a href="mailto: tbasepayne@sjcoe.net" target="_blank">tbasepayne@sjcoe.net</a> for technical support.
                    </p>
                    <div class="theLink">
                        <a href="https://sites.google.com/sjcoe.net/sjcoe-el-networks/home/ca-green-ribbon-schools?authuser=0"
                           target="_blank" class="elLinks2">
                            Visit CA Green Ribbon Schools
                        </a>
                        <span class="elLinkLine"></span>
                    </div>
                </div>
            </div><!-- end lit card"-->


            <div class="lit-card reddish-shadow">
                <img src="./../../../assets/images/teacherscollege-OPT.jpg"
                    alt="Image for Teachers College of San Joaquin" class="lit-img">

                <div class="lit-cardBody">
                    <h2 class="elTopic mb-0">Teachers College</h2>
                    <p class="elSubtopic">of San Joaquin</p>

                    <p class="elText2">

                        Teachers College of San Joaquin (TCSJ) is the only WASC
                        accredited institution of higher education that is a
                        department within a county office of education.
                    </p>

                    <p class="elText2">

                        TCSJ offers a Master’s Degree Program in Environmental Literacy
                        which provides a close examination of the intersection between
                        peoples, societies, and natural systems, and how to relate
                        within those systems in a humble, sustainable manner. Visit TCSJ
                        website to find out more.
                    </p>
                    <div class="theLink">
                        <a href="https://teacherscollegesj.edu/masters-degree-in-education/master-of-education-in-environmental-literacy/" target="_blank" class="elLinks2">
                            Visit Teachers College of San Joaquin
                        </a>
                        <span class="elLinkLine"></span>
                    </div>
                </div>
            </div><!-- end lit card"-->
            
            <div class="lit-card reddish-shadow">
                <img src="./../../../assets/images/green-school-opt.jpg" alt="Image for A Guide to Green Schools"
                    class="lit-img">

                <div class="lit-cardBody">
                    <h2 class="elTopic mb-0">Professional Learning Opportunities</h2>
                    <!--<p class="elSubtopic"></p>-->

                    <p class="elText2">
                        The San Joaquin County Office of Education STEM team provides customizable professional learning to help teachers integrate environmental literacy into their lessons and units.  Reach out to Tamara Basepayne at <a href="mailto: tbasepayne@sjcoe.net" target="_blank">tbasepayne@sjcoe.net</a> to inquire about customizable and other professional learning opportunities.
                    </p>
                    <p class="elText2">
                        The San Joaquin County Office of Education also leads the CREEC Central <a href="https://creec.org/">(California Regional Environmental Education Community)</a> Grant and offers multiple professional learning opportunities throughout the year.  Stipends offered for participating in the CREEC workshops.  Stipends are limited.  Check out the <a href="https://sites.google.com/sjcoe.net/centralcacelp/home" target="_blank">CREEC Central webpage</a> for more information.
                    </p>
                    <!--<div class="theLink">
                        <a href="https://sites.google.com/sjcoe.net/greenschools/a-guide-to-a-green-school" target="_blank" class="elLinks2">
                            Learn More
                        </a>
                    <span class="elLinkLine"></span>
                </div>-->
                </div>
            </div>
        </div>
        </div><!-- end overall green section -->

        <div class="container">
        <!-- Professional Development Section -->
        <app-pro-development id="proDev"></app-pro-development>     
    </div>


<div id="events" class="tanBG" *ngIf="this.filteredEventCards?.length > 0 ">
    <div class="container">
        <div class="row pb-4">
            <div class="col-12 center">
                <h3 class="dateTitle mb-0">Save the Date</h3>
            </div>
            <div class="col-12 center">
                <h4 class="dateSub mt-n3">We hope to see you at our upcoming events!</h4>
            </div>
        </div>

        <div class="row">
            <div class="cont">
                <a class="fcard" *ngFor="let event of this.filteredEventCards" href="{{event.description_Long}}"
                    target="_blank">
                    <h5 class="card-title">{{event.title_Short}}</h5>
                    <p class="card-text">{{event.title_Long}}</p>
                    <div class="btn btn-primary">{{event.event_Address}}</div>
                    <p class="card-date pt-2 mb-n1">{{event.dates}}</p>
                    <p class="card-text">{{event.location_Notes}}</p>
                </a>
            </div>
        </div>

    </div>
</div>
<!-- SJCOE Enviro Team -->
<div class="container img-flow pb-5 mb-5">
    <div class="row">
        <div class="col-12">
            <h2 class="enviroTeamTitle">Meet the SJCOE Enviro Team</h2>
        </div>
    </div>
    <div class="row mt-3">
        <!-- Annie Cunial
    <div class="col-lg-3 col-md-6 col-sm-12 center">
        <div>
            <img src="../../../assets/images/annie-OPT.png" alt="Annie Cunial, Division Director SJCOE STEM Program"
             class="img-fluid">
            <h2 class="teamName">Annie Cunial</h2>
            <p class="teamPos">Division Director</p>
            <p class="teamText2">SJCOE STEM Programs</p>
        </div>
    </div>-->
        <!-- Dean Reese -->
        <div class="col-lg-3 col-md-6 col-sm-12 center">
            <div>
                <img src="../../../assets/images/tamara-OPT.png"
                     alt="Tamara Basepayne Environmental Literacy Coordinator" class="img-fluid">
                <h2 class="teamName">Tamara Basepayne</h2>
                <p class="teamPos">Director of Outdoor Education and STEM Programs</p>
                <p class="teamText2"><a href="mailto: tbasepayne@sjcoe.net" target="_blank">tbasepayne@sjcoe.net</a></p>
            </div>
        </div>
        <!-- Katherine Burns
        <div class="col-lg-3 col-md-6 col-sm-12 center">
            <div>
                <img src="../../../assets/images/katherine-OPT.png" alt="Katherine Burns Coordinator TCSJ"
                class="img-fluid">
                <h2 class="teamName">Katherine Burns</h2>
                <p class="teamPos">Coordinator</p>
                <p class="teamText2">TCSJ</p>
            </div>
        </div>-->
        <!-- Lissa Gilmore -->
        <div class="col-lg-3 col-md-6 col-sm-12 center">
            <div>
                <img src="../../../assets/images/lissa-OPT.png"
                     alt="Lissa Gilmore Outdoor Education Coordinator Durham Ferry SJCOE STEM Programs"
                     class="img-fluid">
                <h2 class="teamName">Lissa Gilmore</h2>
                <p class="teamPos">Outdoor Education Director Sky Mountain and SJCOE STEM Programs</p>
                <p class="teamText2"><a href="mailto: lgilmore@sjcoe.net" target="_blank">lgilmore@sjcoe.net</a></p>
            </div>
        </div>
        <!-- Krinstine Stepping -->
        <div class="col-lg-3 col-md-6 col-sm-12 center">
            <div>
                <img src="../../../assets/images/kristine-OPT.png"
                     alt="Kristine Stepping Manager Durham Ferry STEM Programs" class="img-fluid">
                <h2 class="teamName">Kristine Stepping</h2>
                <p class="teamPos">Coordinator</p>
                <p class="teamText">Durham Ferry STEM Programs</p>
                <p class="teamText2"><a href="mailto: ksteppings@sjcoe.net" target="_blank">ksteppings@sjcoe.net</a></p>
            </div>
        </div>
        <!-- Sinead Klement
    <div class="col-lg-3 col-md-6 col-sm-12 center">
        <div>
            <img src="../../../assets/images/sinead-OPT.png"
             alt="Sinead Klement Teacher on Special Assignment Amador County USD" class="img-fluid">
            <h2 class="teamName">Sinead Klement</h2>
            <p class="teamPos">Teacher on Special Assignment</p>
            <p class="teamText2">Amador County USD</p>
        </div>
    </div>-->
        <!-- Jose Marquez -->
        <div class="col-lg-3 col-md-6 col-sm-12 center">
            <div>
                <img src="../../../assets/images/jose-OPT.png" alt="Jose Marquez STEM Coordinator Stanislaus COE"
                     class="img-fluid">
                <h2 class="teamName">Jose Marquez</h2>
                <p class="teamPos">STEM Coordinator Stanislaus COE</p>
                <p class="teamText2"><a href="mailto: jmarquez@stancoe.org" target="_blank">jmarquez@stancoe.org</a></p>
            </div>
        </div>
        <!-- Tricia Dunlap
        <div class="col-lg-3 col-md-6 col-sm-12 center">
            <div>
                <img src="../../../assets/images/tricia-OPT.png" alt="Tricia Dunlap STEM Consultant Tuolumne County COE"
                 class="img-fluid">
                <h2 class="teamName">Tricia Dunlap</h2>
                <p class="teamPos">STEM Consultant</p>
                <p class="teamText2">Tuolumne County COE</p>
            </div>
        </div>-->
        <!--Katie Coelho-->
        <div class="col-lg-3 col-md-6 col-sm-12 center">
            <div>
                <img src="../../../assets/images/KatieC-OPT.jpg" alt="Katie Coelho STEM Consultant Tuolumne County COE"
                     class="img-fluid">
                <h2 class="teamName">Katie Coelho</h2>
                <p class="teamPos">Manager</p>
                <p class="teamText">Durham Ferry ATEM Programs</p>
                <p class="teamText2"><a href="mailto: kcoelho@sjcoe.net" target="_blank">kcoelho@sjcoe.net</a></p>
            </div>
        </div>
    </div>
</div>
<!-- Mission Statement Row -->
<!-- <div class="missionBG">
    <div class="container">
        <div class="row center">
            <div class="col-12">
                <p class="missionTitle">Our Mission</p>
            </div>
            <div class="col-12">
                <p class="missionText">
                    SJCOE STEM Staff offers a variety of professional learning that
                    can be site based, at the county office or self-paced webinars.
                    SJCOE STEM offers professional learning for all K-12 educators
                    and administrators around all things NGSS, Computer Science,
                    Educational Technology and Environmental Literacy. In addition,
                    SJCOE STEM Staff can work with districts to provide customizable
                    STEM workshops to meet specific needs.
                </p>
            </div>
        </div>
    </div>
</div> -->