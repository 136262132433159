import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsComponent } from './pages/events/events.component';
import { LearningComponent } from './pages/learning/learning.component';
import { SharedModule } from '../shared/shared.module'
import { AppRoutingModule } from '../app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    EventsComponent,
    LearningComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
   
  ],
  exports: [
    EventsComponent,
    LearningComponent,
    SharedModule
  ],
})
export class ViewModule { }