import { Injectable } from '@angular/core';
import { IEvent } from '../models/event';
import { EventdataService } from '../services/eventdata.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private EventdataService: EventdataService) { }
  
  async getEvent(id: number): Promise<IEvent> {
    const events = await this.EventdataService.getLiteracyEvents();
    return events.find(x => x.eventId == id);
  }
}
