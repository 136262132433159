export class IEvent {
    eventId: number;
    event_Creator_Id: number;
    event_Creator: string;
    title_Short: string;
    title_Long: string;
    description_Short: string;
    description_Long: string;
    internal_event: boolean;
    images: string;
    section_Student_durham: boolean;
    section_Student_fab: boolean;
    section_Student_k: boolean;
    section_Adult_Stem: boolean;
    section_Adult_District: boolean;
    section_Pro_Lit: boolean;
    tags: string;
    dates: string;
    contact_Name: string;
    contact_Phone: string;
    contact_Email: string;
    event_Link: string;
    event_Address: string;
    location_Notes: string;
    published: boolean;
    isDeleted: boolean;
    isShowcased: boolean;
    hide_Map: boolean;
    altText: string;
    // "eventId": number;
    // "event_Creator_Id": number;
    // "event_Creator": string;
    // "title_Short": string;
    // "title_Long": string;
    // "description_Short": string;
    // "description_Long": string;
    // "internal_event": boolean;
    // "images": string;
    // "section_Student_durham": boolean;
    // "section_Student_fab": boolean;
    // "section_Student_k": boolean;
    // "section_Adult_Stem": boolean;
    // "section_Adult_District": boolean;
    // "tags": string;
    // "dates": string;
    // "contact_Name": string;
    // "contact_Phone": string;
    // "contact_Email": string;
    // "event_Link": string;
    // "event_Address": string;
    // "location_Notes": string;
    // "published": boolean;
    // "isDeleted": boolean;
    // "isShowcased": boolean;
}