import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { MapComponent } from './components/map/map.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AppRoutingModule } from '../app-routing.module';
import { MobmapComponent } from './components/mobmap/mobmap.component';
import { HomeFilter } from './pipes/homefilter.pipe';
import { NgxSmoothScrollModule } from '@boatzako/ngx-smooth-scroll';
import { ProDevelopmentComponent } from './components/pro-development/pro-development.component';


@NgModule({
  declarations: [
      FooterComponent,
      MapComponent,
      NavbarComponent,
      MobmapComponent,
      HomeFilter,
      ProDevelopmentComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    AppRoutingModule,
    NgxSmoothScrollModule
  ],
  exports: [
      FooterComponent,
      MapComponent,
      MobmapComponent,
      NavbarComponent,
      ProDevelopmentComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }