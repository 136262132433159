import { Component, OnInit } from '@angular/core';
import { EventdataService } from 'src/app/shared/services/eventdata.service';
import { IEvent } from 'src/app/shared/models/event';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pro-development',
  templateUrl: './pro-development.component.html',
  styleUrls: ['./pro-development.component.css']
})
export class ProDevelopmentComponent implements OnInit {

  constructor(private _eventservice: EventdataService, public router: Router,) { }

  selectedValue: string;
  selectedCategories: IEvent[] = [];
  originalEventCards: IEvent[] = [];
  professionalDevCards: IEvent[] = [];

  showcasedCards: IEvent[] = [];
  nonshowcasedCards: IEvent[] = [];

  ngOnInit(): void {
    this._eventservice.getLiteracyEvents().then((res: IEvent[]) => {
      this.originalEventCards = res
        // filtering the event cards array and checking for published bools that are true
        .filter(event => event.published === true && event.section_Pro_Lit === true && event.isDeleted === false)
        .map(event => {
          event.images = `https://stemprodstoragewest.blob.core.windows.net/photos/${event.images.split("|")[0]}`;
          event.tags = event.tags.split(',').join(' • ');
          return event
        });
      this.showcasedCards = this.originalEventCards.filter(event => event.isShowcased === true);
      this.nonshowcasedCards = this.originalEventCards.filter(event => event.isShowcased === false);
      this.professionalDevCards = this.showcasedCards.concat(this.nonshowcasedCards);
      this.selectedCategories = this.originalEventCards;
    });
    this.selectedValue = "All";
    
  }
  toEvent(eventCard: IEvent): void {
    eventCard.internal_event ? this.router.navigate(['/Event', eventCard.eventId]) : window.open(eventCard.description_Long, "_blank");
  }

}
