<div class="containerBG">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <button routerLink="/Home" type="button" class="btn customBtn">
                    <i class="fas fa-chevron-left"></i>
                    Back
                </button>
            </div>
        </div>
        <!-- <div class="row eventPad g-5"> -->
        <div class="eventCont">
            <!-- Desktop main div -->
            <div class=" cardBG">
                <div>
                    <div class="col-12 px-4">
                        <p class="eventTitle">{{event.title_Long}}</p>
                    </div>
                    <div class="col-12 px-0">
                        <div class="img-cov" [ngStyle]="{ 'background-image': 'url('+event.images }"
                        role="img" attr.aria-label="{{event.altText}}"></div>
                    </div>

                    <div class="col-12 description_Short desktop">
                        <h3 class="eventTitle pt-4 pb-2 px-4">{{event.title_Short}}</h3>
                        <p class="pt-2 pb-5 text-body" [innerHTML]="event.description_Long"></p>
                    </div>

                    <!-- Mobile -->
                    <div class="mobile">
                        <div class="col-12 textPad datePad">
                            <p class="mb-0">Date</p>
                            <p class="dateText">{{event.dates}}</p>
                        </div>


                        <div class="col-12">
                            <div class="row">
                                <h3 class="eventTitle pt-4 pb-2 px-4">{{event.title_Short}}</h3>
                                <p class="pt-2 pb-5 text-body" [innerHTML]="event.description_Long"></p>
                            </div>
                        </div>



                        <div class="pb-n3">
                            <button type="button" class="btn collapseBtn" (click)="collapse.toggle(); toggleDisplay()"
                                [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                                <div class="row">
                                    <div class="col-8 d-flex justify-content-start textPad dropdownText">
                                        Contact Information
                                    </div>
                                    <div class="col-4 d-flex justify-content-end alignChevron">
                                        <div class="col-4 d-flex justify-content-end alignChevron">
                                            <i id="contactChev" class="fas fa-chevron-down"></i>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>






                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                            <div class="card pt-n1">
                                <div class="card-body pt-1 px-0">
                                    <p class="mb-0 textPad">
                                        {{event.contact_Name}}<br>
                                        {{event.contact_Phone}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mobileGrey px-0 mt-3">
                            <p class="textPad datePad dropdownText">Location</p>
                            <p class="textPad dateFont">{{event.event_Address}}</p>


                            <div *ngIf="event.hide_Map !== true">
                                <div [innerHtml]="this.mymap2"></div>

                                <a href='https://www.google.com/maps/dir//" + {{event.event_Address}} + "'
                                    target="_blank">
                                    <p style="margin-left:15px;" class="directionsText">Get Directions</p>
                                </a>
                            </div>
                        </div>

                    </div>
                    <!--    <div class="col-12">
                        <h3 class="eventTitle pt-4 pb-2 px-4">{{event.title_Short}}</h3>
                        <p class="pt-2 pb-5 text-body" [innerHTML]="event.description_Long"></p>
                    </div> -->
                </div>
            </div>

            <!-- sidebar -->
            <div class="desktop">
                <div class="mobileEventDetails sideBarpad">
                    <div class="col-12 mt-1 mb-3">
                        <p class="colTitle">Date</p>
                        <p class="boldish">{{event.dates}}</p>
                    </div>
                    <div class="col-12 mb-3">
                        <p class="colTitle">Contact</p>
                        <p class="boldish mb-0">{{event.contact_Name}}</p>
                        <p class="boldish">{{event.contact_Phone}}</p>
                    </div>
                    <div class="col-12 mb-3">
                        <p class="colTitle">Location</p>
                        <p class="boldish mb-0">{{event.event_Address}}</p>
                    </div>
                    <div class="col-12" *ngIf="event.hide_Map !== true">
                        <div [innerHtml]="this.mymap"></div>
                        <a href='https://www.google.com/maps/dir//" + {{event.event_Address}} + "' target="_blank">
                            <p class="directionsText">Get Directions</p>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>