import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsComponent } from './view/pages/events/events.component';
import { LearningComponent } from './view/pages/learning/learning.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo:'Home'},
 
  {path: "Home", component: LearningComponent},
  {path: "Events/:typeId", component: EventsComponent},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }
