import { Component, OnInit } from '@angular/core';
import { EventdataService } from 'src/app/shared/services/eventdata.service';
import { IEvent } from 'src/app/shared/models/event';
import { SmoothScrollDirective, SmoothScrollService } from '@boatzako/ngx-smooth-scroll';

@Component({
  selector: 'app-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.css']
})
export class LearningComponent implements OnInit {

  constructor(private _eventservice: EventdataService, private smooth: SmoothScrollService) { }

  selectedValue: string;
  selectedCategories: IEvent[] = [];
  filteredEventCards: IEvent[] = [];
  originalEventCards: IEvent[] = [];
  professionalDevCards: IEvent[] = [];

  showcasedCards: IEvent[] = [];
  nonshowcasedCards: IEvent[] = [];

  ngOnInit(): void {
    this._eventservice.getLiteracyEvents().then((res: IEvent[]) => {
      this.smooth.smoothScrollToAnchor();
      this.originalEventCards = res
        // filtering the event cards array and checking for published bools that are true
        .filter(event => event.published === true && event.section_Adult_Stem === true && event.isDeleted === false)
        .map(event => {
          event.tags = event.tags.split(',').join(' • ');
          return event
        });
      this.showcasedCards = this.originalEventCards.filter(event => event.isShowcased === true);
      this.nonshowcasedCards = this.originalEventCards.filter(event => event.isShowcased === false);
      this.selectedCategories = this.originalEventCards;
      this.filteredEventCards = this.showcasedCards.concat(this.nonshowcasedCards);
    });
    this.selectedValue = "All";
    this.handleSelectedCategories(this.selectedValue);
    
  }

  handleSelectedCategories(values: string) {
    let cardInfo: any[] = [];
    if (this.selectedValue === "Fab Lab") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_Student_fab === true ? this.filteredEventCards.push(x) : null);
    }
    else if (this.selectedValue === "Durham Ferry") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_Student_durham === true ? this.filteredEventCards.push(x) : null);
    }
    else if (this.selectedValue === "K-6") {
      cardInfo = this.selectedCategories;
      this.filteredEventCards = [];
      cardInfo.map(x => x.section_Student_k === true ? this.filteredEventCards.push(x) : null);
    }
    else {
      this.filteredEventCards = this.selectedCategories;
    }
    console.log(this.filteredEventCards);
  }

}
