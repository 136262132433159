import { Component, OnInit, HostListener } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
​​import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SmoothScrollService } from '@boatzako/ngx-smooth-scroll';
import { EventdataService } from 'src/app/shared/services/eventdata.service';
import { IEvent } from 'src/app/shared/models/event';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
  faTimes = faTimes;  
  public isMenuCollapsed = true;
  btnClicked: boolean = true;

  selectedValue: string;
  selectedCategories: IEvent[] = [];
  originalEventCards: IEvent[] = [];
  professionalDevCards: IEvent[] = [];
  litcards: IEvent[] = [];

  showcasedCards: IEvent[] = [];
  nonshowcasedCards: IEvent[] = [];

  constructor(public router:Router, private _eventservice: EventdataService) { }
  ngOnInit(): void {

    this._eventservice.getLiteracyEvents().then((res: IEvent[]) => {
      this.originalEventCards = res
        // filtering the event cards array and checking for published bools that are true
        .filter(event => event.published === true && event.section_Pro_Lit === true && event.isDeleted === false || event.section_Adult_Stem === true )
        .map(event => {
          event.images = `https://stemprodstoragewest.blob.core.windows.net/photos/${event.images.split("|")[0]}`;
          event.tags = event.tags.split(',').join(' • ');
          return event
        });
      this.showcasedCards = this.originalEventCards.filter(event => event.isShowcased === true && event.section_Pro_Lit === true);
      this.nonshowcasedCards = this.originalEventCards.filter(event => event.isShowcased === false && event.section_Pro_Lit === true);
      this.professionalDevCards = this.showcasedCards.concat(this.nonshowcasedCards);
     
      this.litcards = this.originalEventCards.filter(event => event.published === true && event.section_Adult_Stem === true);

    

      this.selectedCategories = this.originalEventCards;
    });
    this.selectedValue = "All";
    
 } 
//  end of original function

}